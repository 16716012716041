if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister()
    })
  })

  if (navigator.serviceWorker.controller) {
    window.location.reload()
  }
}
