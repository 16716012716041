import { config, cdp } from '../index'
import { getSHA256Hash } from '@/utils/crypto'

const state = () => {
  return {
    config: null
  }
}

const actions = {
  async getConfig({ commit }) {
    try {
      const response = await config.get()
      commit('SET_CONFIG', response.data)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },

  async unsubscribe(_, params) {
    try {
      const response = await cdp.post('/v1/unsubscribe', params)
      return response?.data
    } catch (e) {
      return e
    }
  },

  async unsubscribeByMsisdn(_, params) {
    const str = `${17 + 39}:${Date.now()}`
    const sha256Hash = await getSHA256Hash(str)

    const config = {
      headers: { 'X-Math-Hash': sha256Hash }
    }

    try {
      const response = await cdp.post('/unsubscribe/by-msisdn', params, config)
      return response?.data
    } catch (e) {
      return e
    }
  },

  async unsubscribeByEmail(_, params) {
    try {
      const response = await cdp.post('/unsubscribe/by-email', params)
      return response?.data
    } catch (e) {
      return e
    }
  }
}

// mutations
const mutations = {
  ['SET_CONFIG'](state, config) {
    state.config = config
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
